
import { Tag,Skeleton,Tooltip  } from 'antd';
import { useState,useEffect  } from "react";
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/DetailPage.css'
import '../common/css/ArkreenMain.css'
import {formatVisualizationDate,formatTxTypeTag,formatDate} from '@/utils/DataFormatUtils'
import {useLocation,useNavigate } from 'react-router-dom'
import {SuccessIcon,StatusIcon,TypeIcon,BlockLineIcon,TimestampIcon} from '@/icons/ArkreenIcon'
import ArkreenInfoHeaderCard from '../components/ArkreenInfoHeaderCard'
import TransactionApi from '@/api/TransactionApi';
import ArkreenDetailTd from '../components/ArkreenDetailTd'
import ReactJson from 'react-json-view'

function TransactionDetail(){
  const navigate  = useNavigate()
  const location  = useLocation()
  const hash =  location.pathname.replace('/tx/','')
 const [txHash,setTxHash] = useState('')
 
 const [txInfo,setTxInfo] = useState({
  fromAddress: undefined,
  ownerAddress: undefined,
  height: undefined,
  hash: undefined,
  status: undefined,
  data: undefined,
  type: undefined,
  createTime: undefined,
 })

function getTransactionByHash() {
  
  setTxHash(hash)
  TransactionApi.getTransactionByHash(hash).then((resp:any) => {
    if(!resp.error){
      if(resp.result && resp.result.status!==undefined){
        resp.result.status = <Tag icon={<SuccessIcon />} color=" #E9F6F3" style={{color:'#1FA484'}}>Normal</Tag>
        resp.result.data = JSON.parse(resp.result.data)
        setTxInfo(resp.result)
      }else{
        navigate("/404")
      }
    }else{
      navigate("/404")
    }
  });
}

function showBlockDetail(address:string){
  navigate('/block/'+address)
}

useEffect(() => {
  getTransactionByHash()
},[location.pathname])//eslint-disable-line
useEffect(() => {
  getTransactionByHash()
},[])//eslint-disable-line

  return (
    <div className='arkreen-main'>
      <div className='detail-header'>
        <ArkreenInfoHeaderCard title='Transaction' value={txHash}/>
        <div style={{paddingBlockStart:'20px'}}>
          <ArkreenDetailTd icon={<StatusIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Status" content={txInfo.status} />
          <ArkreenDetailTd icon={<TypeIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Type" content={txInfo.type?formatTxTypeTag(txInfo.type):""} />
          <ArkreenDetailTd icon={<BlockLineIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Block" content={txInfo.height?<span onClick={() => {showBlockDetail(""+txInfo.height)}} className="content-td-link">{Number(txInfo.height).toLocaleString()}</span>:''} />
          <ArkreenDetailTd icon={<TimestampIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Age" content={txInfo.createTime?<Tooltip placement="right" title={formatDate(txInfo.createTime?txInfo.createTime*1000:0,'yyyy-MM-dd HH:mm:ss')}>{formatVisualizationDate(Number(txInfo.createTime))}</Tooltip>:''} />
        </div>
      </div>
      <div className='detail-table-box detail-table-box-tx'>
        <div style={{fontSize:'16px',lineHeight:'32px',paddingBlockEnd:'10px',marginTop:'-6px'}}>
          <span>Transaction Data</span>
        </div>
        <div>
          {
            (txInfo.data)?<ReactJson src={txInfo.data} name={false} collapseStringsAfterLength={32} />:<Skeleton.Input style={{height:'220px'}} block={true} active size='small' />
          }
        </div>
      </div>
    </div>
  );
}

export default TransactionDetail;