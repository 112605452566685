import {Tabs, Tag, Tooltip} from 'antd';
import React, {useState, useEffect} from "react";
import MinerApi from '../api/MinerApi'
import TransactionApi from '../api/TransactionApi'
import StatApi from '../api/StatApi'
import type {ColumnsType} from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/DetailPage.css'
import '../common/css/ArkreenMain.css'
import '../common/css/ArkreenSelect.css'
import {
    formatLongString,
    formatVisualizationDate,
    formatElectricity,
    formatMinerTypeTag,
    formatDate,
    formatPowerUnit2,
    formatElectricity2,
    minerTypeList
} from '@/utils/DataFormatUtils'
import ArkreenTable from '../components/ArkreenTable'
import {useLocation} from 'react-router-dom'
import {
    SuccessIcon,
    ErrorIcon,
    WarningIcon,
    EnergyIcon,
    RewardIcon,
    CapacityIcon,
    OwnerIcon,
    MakerIcon,
    OnboardingTimeIcon,
    ExpiredTimeIcon,
    TypeIcon,
    RegionIcon
} from '@/icons/ArkreenIcon'
import {useNavigate} from 'react-router-dom'
import type {TabsProps} from 'antd';
import moment from 'moment'
import ArkreenDetailTd from '../components/ArkreenDetailTd'
import ArkreenInfoHeaderCard from '../components/ArkreenInfoHeaderCard'
import ArkreenStackAreaChart from '../components/ArkreenStackAreaChart'
import {queryWeatherByH3Index} from "@/utils/queryWeatherUtils";
import {CloudOutlined, EnvironmentOutlined} from "@ant-design/icons";
import {TOKEN_NAME} from "@/store/config";

interface DataType {
    power: number;
    total: string;
    height: number;
    hash: string;
    timestamp: number;
    epoch: number;
}

function MinerDetail() {

    const location = useLocation()
    const address = location.pathname.replace('/miner/', '')
    const navigate = useNavigate()
    const [minerAddress, setMinerAddress] = useState(address)
    const [data, setData] = useState<any>([]);
    const [extraRewardData, setExtraRewardData] = useState<any>([]);
    const [reportList, setReportList] = useState<any[]>([])
    const [rewardsList, setRewardsList] = useState<any[]>([]);
    const utm = new URLSearchParams(location.search)
    const [loadingTx, setLoadingTx] = useState(true)
    const [txType, setTxType] = useState(utm.get("type") ? Number(utm.get("type")) : 'report')
    const [currentPageTx, setCurrentPageTx] = useState(utm.get("page") ? Number(utm.get("page")) : 1)
    const [loadingReward, setLoadingReward] = useState(true)
    const [currentPageReward, setCurrentPageReward] = useState(utm.get("page") ? Number(utm.get("page")) : 1)
    const [growthDisplay, setGrowthDisplay] = useState('block')
    const [minerInfo, setMinerInfo] = useState({
        address: undefined,
        ownerAddress: undefined,
        minerType: undefined,
        maker: undefined,
        locationType: undefined,
        regionName: undefined,
        regionCode: undefined,
        stakingFee: undefined,
        h3index: undefined,
        terminate: undefined,
        power: undefined,
        peakPower: undefined,
        totalEnergyGeneration: undefined,
        totalReward: undefined,
        runStatus: undefined,
        status: undefined,
        balance: undefined,
        onboardTime: undefined,
        expiredTime: undefined,
        capacity: undefined,
        totalExtraAmount: undefined,
        region: undefined,
        panelLink:undefined
    })
    const [weather, setWeather] = useState<any>();
    const [isPlantMiner, setIsPlantMiner] = useState(false)

    const columnsTx: ColumnsType<DataType> = [
        {
            title: 'Hash',
            dataIndex: 'hash',
            key: 'hash',
            width: '180px',
            fixed: 'left',
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            width: '120px',
        },
        {
            title: 'Power(W)',
            dataIndex: 'power',
            key: 'power',
            width: '120px',
        },
        {
            title: 'Energy(kWh)',
            dataIndex: 'total',
            key: 'total',
            width: '160px',
        },
        {
            title: 'Age',
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: '180px',
        },
    ];
    const columnsReward: ColumnsType<DataType> = [
        {
            title: 'Hash',
            dataIndex: 'hash',
            key: 'hash',
            width: '180px',
            fixed: 'left',
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            width: '120px',
        },
        {
            title: 'Reward('+TOKEN_NAME+')',
            dataIndex: 'amount',
            key: 'amount',
            width: '120px',
        },
        {
            title: 'Reward Percent',
            dataIndex: 'rewardPercent',
            key: 'rewardPercent',
            width: '120px',
        },
        {
            title: 'Age',
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: '180px',
        },
    ];
    function showTxDetail(hash: string) {
        navigate('/tx/' + hash)
    }
    async function getReportList() {
        const data = {
            address: minerAddress,
            offset: currentPageTx,
        }
        setLoadingTx(true)
        let rsp: any = {}
        if (minerInfo.minerType === 9) {
            rsp = await TransactionApi.getPocMinerReportList(data);
        } else {
            rsp = await TransactionApi.getMinerReportList(data);
        }
        if (!rsp.error) {
            const reportArr = new Array(rsp.result.length);
            rsp.result.forEach((item: any) => {
                const report = {
                    key: item.hash + "-" + Math.random(),
                    hash: <span onClick={() => {
                        showTxDetail(item.hash)
                    }} className="content-td-link">{formatLongString(item.hash, 7, 7)}</span>,
                    height: <span onClick={() => {
                        showBlockDetail(item.height)
                    }} className="content-td-link">{Number(item.height).toLocaleString()}</span>,
                    power: <span>{formatPowerUnit2(Number(item.power) / 1000)}</span>,
                    total: <span>{formatElectricity2(Number(item.total) / 1000)}</span>,
                    timestamp: <span>{formatVisualizationDate(item.timestamp)}</span>,
                }
                reportArr.push(report)
            })
            setReportList(reportArr)
        }
        setLoadingTx(false)
    }
    async function getRewards() {
        const data = {
            address: minerAddress,
            offset: currentPageReward,
            limit: 25,
        }
        setLoadingReward(true)
        setRewardsList([])
        const rsp: any = await TransactionApi.getMinerRewardList(data);
        if (!rsp.error && rsp.result.length) {
            const RewardsList: any = [];
            rsp.result.forEach((item: any) => {
                const reward = {
                    key: item.hash,
                    address: item.address,
                    accountType: item.accountType,
                    ownerAddress: item.ownerAddress,
                    amount: <span>{Number(item.amount).toLocaleString() + ""}</span>,
                    hash: <span onClick={() => {
                        showTxDetail(item.hash)
                    }} className="content-td-link">{formatLongString(item.hash, 7, 7)}</span>,
                    height: <span onClick={() => {
                        showBlockDetail(item.height)
                    }} className="content-td-link">{Number(item.height).toLocaleString()}</span>,
                    rewardPercent: <span>{Number(item.rewardPercent).toFixed(2) + " %"}</span>,
                    timestamp: <span>{formatVisualizationDate(item.timestamp)}</span>
                }
                RewardsList.push(reward)
            })
            setRewardsList(RewardsList)
        }
        setLoadingReward(false)
    }
    async function stat() {
        const startTime = formatDate(Number(minerInfo.onboardTime) * 1000, 'yyyyMMdd');
        const endTime = formatDate(Date.now(), 'yyyyMMdd');
        const data = {
            address: minerAddress,
            startTime: startTime,
            endTime: endTime,
        }
        //const days = getDates(new Date(Number(minerInfo.onboardTime) * 1000), new Date())
        const deviceDaily: any = await StatApi.statDeviceDailyByMiner(data);
        if (deviceDaily.error || deviceDaily.result.length <= 0) {
            setGrowthDisplay('none');
        } else {
            const dateArr: any = []
            const greenEnergyObj: any = {}
            const dailyRewardObj: any = {}
            deviceDaily.result.forEach((item: any) => {
                greenEnergyObj[item.dataDate] = item.energyGenerationIncrement
                dailyRewardObj[item.dataDate] = item.rewardIncrement
            });

            for (let i = deviceDaily.result.length-1; i >= 0; i--) {
                const day = deviceDaily.result[i].dataDate;
                const dayStr = moment(day).format('yyyy-MM-DD')
                //let energy = 0
                let reward = 0
                if (greenEnergyObj[dayStr]) {
                    //energy = Number(greenEnergyObj[dayStr]) / 1000
                }
                if (dailyRewardObj[dayStr]) {
                    reward = Number(dailyRewardObj[dayStr])
                }
                dateArr.push({dataDate: dayStr, value: reward, seriesField: 'Reward'})
                //dateArr.push({dataDate:dayStr, value:energy, seriesField:'Energy'})
            }
            setData(dateArr)
        }
    }
    async function statPocMinerExtraData(minerAddress: string, startDay: string, endDay: string) {
        const data = {
            address: minerAddress,
            startDay: startDay,
            endDay: endDay,
        }
        const response: any = await StatApi.statPocDailyByMiner(data);
        console.log('####StatApi-statPocDailyByMiner-response:' + JSON.stringify(response))
        if (response.result) {
            const dataArr: any[] = []
            for (var i = response.result.length - 1; i >= 0; i--) {
                const item = response.result[i]
                const dayStr = item.dataDate
                var m7 = Math.round(Number(item.electricityM7))
                var m30 = Math.round(Number(item.electricityM30))
                dataArr.push({dataDate: dayStr, value: m7, seriesField: 'MA7'})
                dataArr.push({dataDate: dayStr, value: m30, seriesField: 'MA30'})
            }
            setExtraRewardData(dataArr)
        }
    }
    function getMinerByAddress() {
        setMinerAddress(address)
        MinerApi.getMinerByAddress(address).then((resp: any) => {
            if (!resp.error) {
                const status = resp.result.status;
                const runStatus = resp.result.runStatus;
                if (status === 2) {
                    if (runStatus === 0) {
                        resp.result.status =
                            <Tag icon={<SuccessIcon/>} color=" #E9F6F3" style={{color: '#1FA484'}}>Normal</Tag>
                    } else {
                        resp.result.status =
                            <Tag icon={<WarningIcon/>} color=" #FCF5EC" style={{color: '#DF9742'}}>Abnormal</Tag>
                    }
                } else if (status === 3) {
                    resp.result.status = resp.result.status =
                        <Tag icon={<ErrorIcon/>} color=" #FCF0F0" style={{color: '#E16969'}}>Terminated</Tag>
                } else {
                    resp.result.status = resp.result.status =
                        <Tag icon={<WarningIcon/>} color=" #FCF5EC" style={{color: '#DF9742'}}>Abnormal</Tag>
                }
                if (resp.result.ext) {
                    resp.result.expiredTime = resp.result.onboardTime + resp.result.ext.expiredTime
                    resp.result.panelLink = resp.result.ext.panelLink
                    resp.result.region = resp.result.ext.region
                }
                setMinerInfo(resp.result)
            } else {
                navigate("/404")
            }
        });
    }
    function showOwnerDetail(address: string) {
        navigate('/account/' + address)
    }

    function showMinerDetail(address: string) {
        navigate('/miner/' + address)
    }
    function showBlockDetail(height: number) {
        navigate('/block/' + height)
    }
    //
    const items: TabsProps['items'] = [
        {
            key: 'report',
            label: 'Report',
            children: <ArkreenTable scroll={{x: 760}} currentPage={currentPageTx} columns={columnsTx}
                                    datalist={reportList} loading={loadingTx} onPageChange={setCurrentPageTx}/>,
        },
        {
            key: 'reward',
            label: 'Reward',
            children: <ArkreenTable scroll={{x: 720}} currentPage={currentPageReward} columns={columnsReward}
                                    datalist={rewardsList} loading={loadingReward}
                                    onPageChange={setCurrentPageReward}/>,
        }
    ];
    const itemsPlantMiner: TabsProps['items'] = [
        {
            key: 'reward',
            label: 'Reward',
            children: <ArkreenTable scroll={{x: 720}} currentPage={currentPageReward} columns={columnsReward}
                                    datalist={rewardsList} loading={loadingReward}
                                    onPageChange={setCurrentPageReward}/>,
        }
    ];
    const onChange = (key: string) => {
        setTxType(key)
        let data = new URLSearchParams(location.search);
        data.set('type', "" + key);
        data.set('page', "1");
        setCurrentPageTx(1);
        setCurrentPageReward(1);
        navigate(`?` + data.toString(), {state: {fromHome: true}})
    };
    async function queryWeather() {
        if (minerInfo && minerInfo.minerType === 2) {
            const data = await queryWeatherByH3Index(Number(minerInfo.h3index))
            if (data) {
                setWeather(data)
            }
        }
    }

    const enableWeather = false

    useEffect(() => {
        if(enableWeather){
            queryWeather()
        }
    }, [minerInfo]);//eslint-disable-line

    useEffect(() => {
        getMinerByAddress()
    }, [address])//eslint-disable-line

    useEffect(() => {
        if (minerInfo.address) {
            getReportList();
        }
    }, [currentPageTx])//eslint-disable-line

    useEffect(() => {
        if (minerInfo.address) {
            getRewards();
        }
    }, [currentPageReward])//eslint-disable-line

    useEffect(() => {
        if (minerAddress && minerInfo.address) {
            getReportList();
            getRewards();
            stat();
            if (minerInfo.minerType && minerInfo.minerType === minerTypeList[8].index) {
                const today = moment.utc().format('yyyyMMDD')
                const startDay = moment.utc(today).subtract(30, 'day').format("yyyyMMDD");
                statPocMinerExtraData(minerInfo.address, startDay, today);
            }
            if(minerInfo.minerType === minerTypeList[9].index){
                setIsPlantMiner(true)
            }else {
                setIsPlantMiner(false)
            }
        }
    }, [minerInfo.address])//eslint-disable-line

    return (
        <div className='arkreen-main'>
            <div className='detail-header'>
                <ArkreenInfoHeaderCard title='Miner' value={minerAddress}/>
                <div className='detail-header-info-box'>
                    <div className='detail-header-info-box-left'>
                        <ArkreenDetailTd icon={<TypeIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Type" content={minerInfo.minerType ? formatMinerTypeTag(minerInfo.minerType) : ''}/>
                        {/* <ArkreenDetailTd icon={<StatusIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Status" content={minerInfo.status} /> */}
                        <ArkreenDetailTd icon={<RewardIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Total Reward" content={minerInfo.totalReward ? (Number(Number(minerInfo.totalReward).toFixed(2)).toLocaleString() + " " + TOKEN_NAME) : ''}/>
                        {
                            minerInfo.minerType && minerInfo.minerType === minerTypeList[8].index && minerInfo.totalExtraAmount && <ArkreenDetailTd icon={<RewardIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Total Extra Reward" content={minerInfo.totalExtraAmount ? (Number(Number(minerInfo.totalExtraAmount).toFixed(2)).toLocaleString() + " " + TOKEN_NAME) : ''}/>
                        }
                        <ArkreenDetailTd icon={<EnergyIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Energy" content={minerInfo.totalEnergyGeneration ? formatElectricity(Number(minerInfo.totalEnergyGeneration)) : ''}/>
                        <ArkreenDetailTd icon={<CapacityIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Peak Power" content={minerInfo.peakPower === undefined ? '' : Number(minerInfo.peakPower / 1000).toLocaleString() + " W"}/>
                        <ArkreenDetailTd icon={<OwnerIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Owner" content={minerInfo.ownerAddress ? <span onClick={() => {showOwnerDetail("" + minerInfo.ownerAddress)}} className="content-td-link">{formatLongString("" + minerInfo.ownerAddress, 7, 7)}</span> : ''}/>
                        <ArkreenDetailTd icon={<MakerIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Issuer"
                                         content={<span onClick={() => {
                                             const maker = minerInfo?.maker ? minerInfo.maker : ''
                                             if (minerInfo?.maker && maker.length === 42) {
                                                 showMinerDetail("" + minerInfo?.maker)
                                             }
                                         }} className={(minerInfo?.maker && (''+minerInfo.maker).length === 42)?'content-td-def':''}>{formatLongString('' + minerInfo.maker, 7, 7)}</span>}/>
                        {
                            minerInfo.region && <ArkreenDetailTd icon={<RegionIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Region" content={<span>{minerInfo.region['city']}/{minerInfo.region['country']}</span>}/>
                        }
                        <ArkreenDetailTd icon={<OnboardingTimeIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Onboarding Time" content={minerInfo.onboardTime ? (
                            <Tooltip placement="right" title={formatDate(minerInfo.onboardTime ? minerInfo.onboardTime * 1000 : 0, 'yyyy-MM-dd HH:mm:ss')}>{formatVisualizationDate(Number(minerInfo.onboardTime))}</Tooltip>) : ''}/>
                        {
                            minerInfo.expiredTime && <ArkreenDetailTd icon={<ExpiredTimeIcon style={{width: '14.33px', height: '14.33px'}}/>} title="Expired Time" content={formatVisualizationDate(Number(minerInfo.expiredTime))}/>
                        }
                        {
                            (weather ? <ArkreenDetailTd icon={<EnvironmentOutlined style={{width: '14.33px', height: '14.33px'}}/>} title="City" content={weather.name}/> : '')
                        }
                        {
                            (weather ?
                                <ArkreenDetailTd icon={<CloudOutlined style={{width: '14.33px', height: '14.33px'}}/>}
                                                 title="Weather"
                                                 content={<div style={{height: '28px', display: 'flex', gap: '8px'}}>
                                                     <div><img alt='' src={weather.icon} width='28px' height='28px'/>
                                                     </div>
                                                     <div style={{lineHeight: '28px'}}><span> {weather.main} </span>
                                                     </div>
                                                 </div>}/> : '')
                        }
                    </div>
                </div>

            </div>
            {
                minerInfo.panelLink && <div className='detail-table-box-tx detail-table-box'>
                    <div className='detail-info-box-header' style={{paddingBlockEnd: '12px'}}><span>Plant Miner Images</span></div>
                    <div className='detail-info-box-header'>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img src={minerInfo.panelLink} alt='miner image' className='miner-images'/>
                    </div>
                </div>
            }
            <div style={{display: growthDisplay}} className='detail-table-box-tx detail-table-box'>
                <ArkreenStackAreaChart height='192px' title='Reward' data={data} xField='dataDate' yField='value' seriesField='seriesField' lineColor={['#1EAAF9', '#00913A']}/>
            </div>

            {
                (minerInfo.minerType && minerInfo.minerType === minerTypeList[8].index && extraRewardData && extraRewardData.length > 0 &&
                    <div className='detail-table-box-tx detail-table-box'>
                        <ArkreenStackAreaChart height='192px' title='Average Energy Consumption' data={extraRewardData} xField='dataDate' yField='value' seriesField='seriesField' lineColor={['#9D68F6', '#00913A']}/>
                    </div>
                )
            }
            <div className='detail-table-box'>
                <Tabs style={{marginTop: '-12px'}} defaultActiveKey={"" + txType} items={isPlantMiner?itemsPlantMiner:items} onChange={onChange}/>
            </div>
        </div>
    );
}

export default MinerDetail;